import React, {useEffect, useState} from 'react';
import './CartItem.scss';
import {TrashIcon} from '@heroicons/react/20/solid';
import Button from '../../../components/Button/Button';
import {useDispatch} from 'react-redux';
import {removeItem, updateItem} from '../../../redux/cart.reducer';

const CartItem = ({qty, product}) => {

  const dispatch = useDispatch();
  const [productInformation, setProductInformation] = useState(undefined);

  useEffect(() => {
    const variantIndex = product.id.split('__')[1];
    setProductInformation(product.variants[variantIndex]);
  }, []);


  const updateQuantity = (qty) => dispatch(updateItem({item: product, qty}));

  return (
      <>
        <li className="cart-item">
          <div className="image-section">
            <img src={product.thumbnail.url} alt={product.thumbnail.label} className="image"/>
          </div>
          {
              productInformation &&
              <div className="info-section">
                <div className="name">{product.name}</div>
                <div className="category">{product.categoryName}</div>
                <table className="product-table">
                  <thead>
                  <tr>
                    <td>Model</td>
                    <td>Weight Capacity</td>
                    <td>Platform Size</td>
                    <td>Price</td>
                    <td className={'small'}>Quantity</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{productInformation.meta.model || '-'}</td>
                    <td>{productInformation.capacity || '-'}</td>
                    <td>{productInformation.platformSize || '-'}</td>
                    <td>{productInformation.price ? `$ ${productInformation.price?.toFixed(2)}` : '-'}</td>
                    <td>
                      <div className={'action-bar controls'}>
                        <input type="number" min={1} max={100} className={'input-value'} value={qty}
                               onChange={(e) => {
                                 updateQuantity(+e.target.value);
                               }}/>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
          }
          <div className="action-section">
            <Button className={'icon-only accent'} aria-label={'Remove Item'} onClick={() => {
              dispatch(removeItem(product));
            }}>
              <TrashIcon className={'heroicon-20'}/>
            </Button>
          </div>
        </li>
      </>
  );
};

export default CartItem;
