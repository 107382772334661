import React from 'react';
import './ProductCard.scss';
import Button from '../Button/Button';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {Link} from 'react-router-dom';
import {ChevronDoubleRightIcon} from '@heroicons/react/20/solid';

const ProductCard = ({product, loading, ...otherProps}) => {


  return (
      <>
        {
          loading ?
              <div className="product-card loading">
                <Skeleton className={'thumbnail'} style={{width: '100%', height: '350px'}}/>
                <div className="text-content">
                  <h2 className="title">
                    <Skeleton style={{width: '120px', height: '30px'}}/>
                  </h2>
                  <h3 className="category-name">
                    <Skeleton style={{width: '80px', height: '20px'}}/>
                  </h3>
                  <div className="cta">
                    <Skeleton style={{width: '100%', height: '30px'}}/>
                  </div>
                </div>
              </div>
              :
              <div className="product-card" tabIndex={-1}>
                <Link to={`/products/${product.categoryId}/${product.id}`} tabIndex={0}>
                  <img src={product.thumbnail.url} alt={product.thumbnail.name} className="thumbnail"/>
                </Link>
                <div className="text-content">
                  <Link to={`/products/${product.categoryId}/${product.id}`}
                        style={{display: 'flex', flex: '1 1 auto'}} tabIndex={0} aria-label={product.name}>
                    <div className="meta-content">
                      <h2 className="title" tabIndex={-1}>{product.name}</h2>
                    </div>
                  </Link>
                  <div className="action-bar cta">
                    <Link to={`/products/${product.categoryId}/${product.id}`} className={'details-button'}
                          tabIndex={0}>
                      <Button className={'outline accent'} tabIndex={-1}>View Details <ChevronDoubleRightIcon
                          className={'heroicon-20'}/></Button>
                    </Link>
                  </div>
                </div>
              </div>
        }

      </>
  );
};

export default ProductCard;
