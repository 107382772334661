import React from 'react';
import './ProductCategoryCard.scss';
import Button from '../Button/Button';
import {ChevronDoubleRightIcon} from '@heroicons/react/20/solid';
import {Link} from 'react-router-dom';

const ProductCategoryCard = ({metaCategory}) => {
  return (
      <>
        <div className="product-category-card" tabIndex={-1}>
          <div className="product-category-card_image-section">
            <Link to={`/products/${metaCategory.id}`} tabIndex={0} aria-label={metaCategory.name}>
              <img src={metaCategory.thumbnail.url} alt={metaCategory.thumbnail.label}
                   className={'product-category-card_image'}/>
            </Link>
          </div>
          <div className="product-category-card_content">
            <h3 className="product-category-card_title" tabIndex={-1}>{metaCategory.name}</h3>
          </div>
          <div className="product-category-card_actions-section">
            <Link className="product-category-card_main-cta" to={`/products/${metaCategory.id}`} tabIndex={0}>
              <Button className={'outline accent product-category-card_main-cta'} tabIndex={-1}>
                View Products <ChevronDoubleRightIcon className={'heroicon-20'}/>
              </Button>
            </Link>
          </div>
        </div>
      </>
  );
};

export default ProductCategoryCard;
