import {queryRefToObject} from './common';
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {db} from './index';


export const getUser = async (id) =>
    queryRefToObject(await getDoc(doc(db, 'users', id)));

export const addUser = async (data) => await setDoc(doc(db, 'users'), {
  ...data,
  createdAt: new Date(),
  updatedAt: new Date()
});