// Tabs.js
import React, {useEffect, useState} from 'react';
import './Tabs.scss';

const Tabs = ({children}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (Array.isArray(children)) {
      setTabs(children.filter(value => !!value));
    } else {
      setTabs([children]);
    }
  }, [children]);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
      <>
        <div className="tab-bar">
          {tabs && tabs.map((tab, index) => (
              <>
                {tab.props.label && <button
                    key={index}
                    className={`${activeTab === index ? 'active' : ''} tab`}
                    onClick={() => handleTabClick(index)}
                >
                  {tab.props.label}
                </button>}
              </>
          ))}
        </div>
        <div className="tab-content">
          {tabs[activeTab]}
        </div>
      </>
  );
};

export default Tabs;
