import React, {useEffect, useState} from 'react';
import './Service-Card.scss';
import {Link} from 'react-router-dom';
import Button from '../../Button/Button';
import {ArrowRightCircleIcon} from '@heroicons/react/24/outline';
import Skeleton from 'react-loading-skeleton';
import {getImageDownloadURL} from '../../../firebase/storage';

const ServiceCard = ({service, loader}) => {

  const [imageURL, setImageURL] = useState('');


  const getImageURL = async () => {
    if (service) {
      const imageUrl = await getImageDownloadURL(service.image);
      setImageURL(imageUrl);
    }
  };

  useEffect(() => {
    getImageURL();
  }, [service]);


  if (loader)
    return <li className="service-card">
      <div className="image-area">
        <Skeleton className={'image'}/>
      </div>
      <div className="content">
        <p className={'description-loader'}>
          <Skeleton count={3}/>
        </p>
        <br/>
        <div className="action-bar right">
          <Skeleton style={{width: '80px', height: '30px'}}/>
        </div>
      </div>
    </li>;


  return (
      <>
        <li className="service-card" tabIndex={-1}>
          <div className="image-area" tabIndex={-1}>
            <Link to={service.cta.href} target={service.cta.target} tabIndex={0} aria-label={service.cta.title}>
              <img src={imageURL} alt={service.image} className={'image'}/>
            </Link>
          </div>
          <div className="content">
            <h2 className="card-title">{service.shortTitle}</h2>
            <div dangerouslySetInnerHTML={{__html: service.shortDescription}} className={'short-description'}></div>
            <Link to={service.cta.href} target={service.cta.target} className={'cta primary'} tabIndex={0}>
              <Button className={'accent outline'} tabIndex={-1}>
                {service.cta.label || 'Know More'}
                <ArrowRightCircleIcon className={'heroicon-20'}/>
              </Button>
            </Link>
          </div>
        </li>
      </>
  );
};

export default ServiceCard;
