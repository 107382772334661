import React, {useRef, useState} from 'react';
import './Contact-Us.scss';
import Button from '../../Button/Button';
import {PaperAirplaneIcon} from '@heroicons/react/24/solid';
import {sendMessage} from '../../../firebase/message.store';
import {useDispatch} from 'react-redux';
import {showNotification} from '../../../redux/notification.reducer';
import {addUser, getUser} from '../../../firebase/users.store';

const ContactUs = () => {


	const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const formRef = useRef();

	const [message, setMessage] = useState('');

	const dispatch = useDispatch();

  const handleSubmit = async (e) => {
	e.preventDefault();
	const name = nameRef.current.value;
	const email = emailRef.current.value;
	if (name && email && message) {
		await sendMessage({
			to: {
				email,
				name,
			},
			type: 'contact',
			message,
			data: {}
		});

		const userData = await getUser('karmit199@gmail.com');
		if (!userData.exist()) {
			await addUser({id: email, name, email});
		}

	  dispatch(showNotification({
		message: 'Thank you for reaching out. Have a fantastic day! 😊',
		title: 'Message Received! We\'re on It!',
		type: 'success',
		duration: 5_000
	  }));
	}

	  e.target.reset();
  };

	return (
	  <>
		  <section className="contact-forms">
		  <h1 className={'title'}>Contact Us</h1>
		  <h3 className={'subTitle'}>Reach Out to Us for Any Inquiries or Assistance</h3>
		  <form onSubmit={handleSubmit} ref={formRef}>
			<fieldset name={'name'}>
			  <label htmlFor='name'>Name *</label>
			  <input
					  type="text" name="name" id="name" placeholder={'Enter you full name'}
				  autoComplete={'given-name family-name'}
				  required={true}
				  ref={nameRef}
			  />
			</fieldset>
			<fieldset name={'email'}>
			  <label htmlFor='email'>Email *</label>
			  <input
					  type="email" name="email" id="email" placeholder={'Enter you email'} autoComplete={'email'}
				  required={true}
				  ref={emailRef}
			  />
			</fieldset>
			<fieldset name={'message'} className={'full'}>
			  <label htmlFor='message'>Message *</label>
				<textarea
						name="message"
						id="message"
						placeholder={'Provide us with more information so we can serve you better!'}
						required={true}
						autoComplete={'off'}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
				></textarea>
			</fieldset>
			<div className='action-bar right full'>
			  <Button className={'solid primary pill'} type={'submit'}>
				Send Message
				<PaperAirplaneIcon className={'heroicon-24'}/>
			  </Button>
			</div>
		  </form>
		</section>
	  </>
  );
};

export default ContactUs;
